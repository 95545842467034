import "./ProjectCard.css";

const ProjectCard = ({ details }) => {
    return(
        <div className="flex justify-center mx-3">
            <div className="project-card max-w-sm rounded overflow-hidden p-3 mb-4">
                <div className="px-6 py-4">
                    <div id="project-name" className="bg-gradient-to-bl from-slate-50 via-slate-100 to-slate-200 bg-clip-text text-transparent font-semibold text-xl mb-2">{details.name}</div>
                    <div className="flex justify-start">
                        <div className="project-level mr-2">{details.level}</div>
                    </div>
                    <p className="bg-gradient-to-bl from-slate-50 via-slate-100 to-slate-200 bg-clip-text text-transparent text-base">
                        {details.description}
                    </p>
                </div>

                <div className="px-6 py-2 pb-3">
                    {details.skills.map((item) => (
                        <span key={item} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{item}</span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;