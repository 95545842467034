import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <section id="footer">
          <div className="w-full mx-auto max-w-screen-xl p-1 md:flex md:items-center md:justify-between">
            <span className="text-[1rem] text-gray-500 sm:text-center dark:text-gray-400">© 2024 Jirayu Klinudom. All Rights Reserved.
            </span>
            <ul className="flex flex-wrap justify-center items-center mt-3 text-[1rem] font-large text-gray-500 dark:text-gray-400 sm:mt-0">
                <li>
                    <a href="https://github.com/NoodlesCupCake" target="_blank" className="hover:underline me-4 md:me-6">Github</a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/jirayu-klinudom/" target="_blank" className="hover:underline me-4 md:me-6">LinkedIn</a>
                </li>
                <li>
                    <a href="mailto:jirayuklinudom@gmail.com?subject = Feedback&body = Message" className="hover:underline me-4 md:me-6">Email</a>
                </li>
                <li>
                  <a href="./Resume/Jirayu Klinudom - CV.pdf" target="_blank" className="hover:underline me-4 md:me-6" download>Download Resume</a>
                </li>
            </ul>
          </div>
    </section>
  );
};

export default Footer;
