import React from "react";
import "./Hero.css";
import { MAIN_SKILLS } from "../../utils/data";

const Hero = () => {
  return (
    <section id="hero" className="hero-container">
      <picture>
        
      </picture>
      <div className="hero-content">
        <h2 className="bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">
          Jirayu Klinudom
        </h2>
        <p className="mx-2 bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">
          Greeting, I am James, a graduate computer science student from Mahidol University, passionate about software development and AI, with a focus on NLP and Generative AI.
        </p>
        
        <div className="highlight-skill">
          {MAIN_SKILLS.map((label) => (
            <button key={label} className="me-2 mb-2 mt-5 inline-flex items-center justify-center bg-gradient-to-bl from-cyan-500 to-blue-500 text-white font-bold py-2 px-4 rounded-full">
              <div className="bg-gradient-to-bl from-slate-50 via-slate-100 to-slate-200 bg-clip-text text-transparent">
                {label}
              </div>
            </button>
          ))}
        </div>
      </div>

      <div className="hero-img">
        <div>
          <img src="./assets/images/James.jpg" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
