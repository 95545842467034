import "./MyProject.css";
import ProjectCard from "./ProjectCard/ProjectCard";
import React, { useRef } from "react";
import Slider from "react-slick";
import { PROJECTS } from "../../utils/data";

const MyProject = () => {
  const sliderRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true, // Add autoplay option
    autoplaySpeed: 5000, // Set autoplay speed to 5 seconds (5000 milliseconds)
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const slideRight = () => {
  //   sliderRef.current.slickNext();
  // };

  // const slideLeft = () => {
  //   sliderRef.current.slickPrev();
  // };

  return (
    <section id="projects" className="projects-container my-18">
      <h5>Projects</h5>
      <div className="m-auto">
        {/* <div className="arrow-right" onClick={slideRight}>
          <span className="material-symbols-outlined">chevron_right</span>
        </div>

        <div className="arrow-left" onClick={slideLeft}>
          <span className="material-symbols-outlined">chevron_left</span>
        </div> */}

        <Slider ref={sliderRef} {...settings}>
          {PROJECTS.map((item) => (
            <ProjectCard key={item.title} details={item} />
          ))}
        </Slider>
      </div>
      
    </section>
  );
};


export default MyProject;
