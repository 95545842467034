import "./Education.css";
import * as React from 'react';

const Education = () => {

  return (
    // <div id="education" className="education-h5">
    //   <h5 className="text-start w-full">Education</h5>
    //   <section className="education-container mt-8 mx-2rem mb-6 grid justify-center">
    //     {EDUCATION.map((item, index) => (
    //       <ol id="timeline" key={item.school} className="relative border-l-md border-gray-200 dark:border-gray-700 grid grid-rows-3">
    //           <EducationTimeline index={index} school={item.school} degree={item.degree} duration={item.duration} image_url={item.image_url} major={item.major}/>
    //       </ol>
    //     ))}
    //   </section>
    // </div>

    <div id="education" className="education-h5">
      <h5 className="text-start w-full">Education</h5>
      <section className="education-container mt-8 mx-2rem mb-6 grid justify-center">
        <ol id="timeline" className="relative border-l-md border-gray-200 dark:border-gray-700 grid grid-rows-3">
          <li id="branch" className={`grid content-around items-center mb-10 ms-6`}>
            <div className="text-group mx-4">
              <h3 className="mb-1 text-lg sm:text-2xl font-bold bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Assumption College Sriracha</h3>
              <time className="leading-relaxed block mb-2 text-base sm:text-lg font-normal text-gray-400 dark:text-gray-500 ">May 2017 - March 2020</time>
              <p className="text-base font-normal text-gray-500 dark:text-gray-400">Science-Mathematics</p>
              <p className="mt-1 text-base font-extralight text-gray-500 dark:text-gray-400">Highschool (Grade 10 & 12)</p>
            </div>
            <span className="leading-relaxed absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
              <img src="./assets/images/ACS_logo.gif" alt="ACS"></img>
            </span>
          </li>

          <li id="branch" className={`grid content-around items-center mb-10 ms-6`}>
            <div className="text-group mx-4">
              <h3 className="mb-1 text-lg sm:text-2xl font-bold bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Tower Grove Christian Academy</h3>
              <time className="leading-relaxed block mb-2 text-base sm:text-lg font-normal text-gray-400 dark:text-gray-500 ">September 2018 - May 2019</time>
              <p className="text-base font-normal text-gray-500 dark:text-gray-400">Exchange Student at USA</p>
              <p className="mt-1 text-base font-extralight text-gray-500 dark:text-gray-400">Junior Highschool (Grade 11)</p>
            </div>
            <span className="leading-relaxed absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
              <img src="./assets//images/TGCA_logo.png" alt="TGCA"></img>
            </span>
          </li>

          <li id="branch" className={`grid content-around items-center mb-10 ms-6`}>
            <div className="text-group mx-4">
              <h3 className="mb-1 text-lg sm:text-2xl font-bold bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Mahidol Univesity</h3>
              <time className="leading-relaxed block mb-2 text-base sm:text-lg font-normal text-gray-400 dark:text-gray-500 ">August 2020 - May 2024</time>
              <p className="text-base font-normal text-gray-500 dark:text-gray-400">Computer Science</p>
              <p className="mt-1 text-base font-extralight text-gray-500 dark:text-gray-400">Bachelor Science of Information and Communication Technology (ICT)</p>
            </div>
            <span className="leading-relaxed absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
              <img src="./assets/images/MU_logo.png" alt="ICT"></img>
            </span>
          </li>
        </ol>
      </section>
    </div>
  );
};

export default Education;
