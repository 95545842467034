import React from "react";
import "./Certificate.css";
import { CERTIFICATES } from "../../utils/data";


const Certificate = () => {
  return (
    <section id="certificate" className="certificate-container">
      <h5>Certificate</h5>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16 justify-items-center">
        {CERTIFICATES.map((item) => (
          <div key={item.name} className="flex flex-col items-center">
            <a id="image-container" href={item.link} target="_blank" className="flex justify-center cursor-pointer">
              <img 
                id="certificate-card"
                src={item.path} 
                alt={item.name}
                className="w-10/12 mb-2"
              />
            </a>
            <div id="certificate-title" className="text-center px-10 my-auto mx-12 flex flex-col items-center gap-y-1">
              {item.name}
              <div className="flex flex-row gap-x-2">
              <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-semibold text-gray-700">{item.issuer}</span>
              <span className="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-semibold text-gray-700">{item.issueYear}</span>
              </div>
              
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Certificate;
