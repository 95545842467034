import React from "react";
import "./ExperienceCard.css";

const ExperienceCard = ({ details }) => {
  return (
    <div className="work-experience-card mb-4">
      <h6 className="bg-gradient-to-bl from-slate-50 via-slate-100 to-slate-200 bg-clip-text text-transparent font-semibold">
        {details.title}
      </h6>
      <h6 className="bg-gradient-to-bl from-slate-50 via-slate-100 to-slate-200 bg-clip-text text-transparent font-semibold" style={{ fontSize: "0.875rem" }}>
        {details.location}
      </h6>
      <div className="work-duration">{details.date}</div>

      <ul>
        {details.responsibilities.map((item) => (
          <li key={item} className="bg-gradient-to-bl from-slate-50 via-slate-100 to-slate-200 bg-clip-text text-transparent">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExperienceCard;