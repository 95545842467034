import React from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import Skills from "./components/Skills/Skills";
import Education from "./components/Education/Education";
import MyProject from "./components/MyProject/MyProject";
import WorkExperience from "./components/WorkExperience/WorkExperience";
import Footer from "./components/Footer/Footer";
import RevealOnScroll from "./components/RevealOnScroll/RevealOnScroll";
import Certificate from "./components/Certificate/Certificate";

const App = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <Hero />
        <RevealOnScroll>
         <Education />
        </RevealOnScroll>
        <RevealOnScroll>
          <Skills />
        </RevealOnScroll>
        <RevealOnScroll>
          <WorkExperience />
        </RevealOnScroll>
        <RevealOnScroll>
          <MyProject />
        </RevealOnScroll>
        <RevealOnScroll>
          <Certificate />
        </RevealOnScroll>
        
      </div>

      <Footer />
    </>
  );
};

export default App;
