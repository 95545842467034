// import RevealOnScroll from "../RevealOnScroll/RevealOnScroll";
import "./Skills.css";

const Skills = () => {

  return (
    <section id="skills" className="skills-container mt-8">
      <h5 className="pt-5">Technical Skills</h5>
        <div className="grid grid-cols-5 xl:grid-cols-10 lg:grid-cols-9 md:grid-cols-8 sm:grid-cols-7 gap-5">
            <img className="zoom-in" src="./assets/images/Python_logo.svg" alt="Python"/>
            <img className="zoom-in" src="./assets/images/PyTorch_logo.svg" alt="Pytorch"/>
            <img className="zoom-in" src="./assets/images/Keras_logo.svg" alt="Keras"/>
            <img className="zoom-in" src="./assets/images/ScikitLearn_logo.svg" alt="Scikit"/>
            <img className="zoom-in" src="./assets/images/Tensorflow_logo_new.svg" alt="Tensorflow"/>
            <img className="zoom-in" src="./assets/images/Numpy_logo.svg" alt="Numpy"/>
            <img className="zoom-in" src="./assets/images/Pandas_logo.svg" alt="Pandas"/>
            <img className="zoom-in" src="./assets/images/JavaScript_logo.svg" alt="JavaScript"/>
            <img className="zoom-in" src="./assets/images/C_logo.svg" alt="C"/>
            <img className="zoom-in" src="./assets/images/Java_logo.svg" alt="Java"/>
            <img className="zoom-in" src="./assets/images/Arduino_logo.svg" alt="Arduino"/>
            <img className="zoom-in" src="./assets/images/NodeRed_logo.svg" alt="NodeRed"/>
            <img className="zoom-in" src="./assets/images/ReactJS_logo.svg" alt="ReactJS"/>
            <img className="zoom-in" src="./assets/images/Streamlit_logo.svg" alt="Streamlit_logo"/>
            <img className="zoom-in" src="./assets/images/NextJS_logo.svg" alt="NextJS"/>
            <img className="zoom-in" src="./assets/images/DotNet_logo.svg" alt="DotNet"/>
            <img className="zoom-in" src="./assets/images/HTML_logo.svg" alt="HTML"/>
            <img className="zoom-in" src="./assets/images/CSS_logo.svg" alt="CSS"/>
            <img className="zoom-in" src="./assets/images/TailwindCSS_logo.svg" alt="TailwindCSS"/>
            {/* <img className="zoom-in" src="./assets/images/Bootstrap_logo.svg" alt="Bootstrap"/> */}
            <img className="zoom-in" src="./assets/images/NodeJS_logo.svg" alt="NodeJS"/>
            <img className="zoom-in" src="./assets/images/ExpressJS_logo.svg" alt="ExpressJS"/>
            <img className="zoom-in" src="./assets/images/FastAPI_logo.svg" alt="FastAPI"/>
            <img className="zoom-in" src="./assets/images/CS_logo.svg" alt="CS"/>
            <img className="zoom-in" src="./assets/images/PostgreSQL_logo.svg" alt="PostgreSQL"/>
            <img className="zoom-in" src="./assets/images/MySQL_logo.svg" alt="MySQL"/>
            <img className="zoom-in" src="./assets/images/Supabase_logo.svg" alt="Supabase"/>
            {/* <img className="zoom-in" src="./assets/images/MongoDB_logo.svg" alt="MongoDB"/> */}
            <img className="zoom-in" src="./assets/images/Docker_logo.svg" alt="Docker"/>
            
          </div>
    </section>
  );
};

export default Skills;
