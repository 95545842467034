import React, { useState } from "react";
import "./Navbar.css";
import MobileNav from "./MobileNav/MobileNav";
import { Link } from "react-scroll";

function NavBar() {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <>
      <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />

      <nav className="nav-wrapper">
        <div className="nav-content">
          {/* <img className="logo" src="./assets/images/logo.svg" alt="" /> */}

          <ul>
            <li>
              <Link activeClass="active" to="hero" smooth spy offset={-100} className="menu-item bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Home</Link>
            </li>

            <li>
              <Link activeClass="active" to="education" smooth spy offset={-100} className="menu-item bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Education</Link>
            </li>

            <li>
              <Link activeClass="active" to="skills" smooth spy offset={-120} className="menu-item bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Skills</Link>
            </li>

            <li>
              <Link activeClass="active" to="work-exp" smooth spy offset={-100} className="menu-item bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Experience</Link>
            </li>

            <li>
              <Link activeClass="active" to="projects" smooth spy offset={-100} className="menu-item bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Projects</Link>
            </li>

            <li>
              <Link activeClass="active" to="certificate" smooth spy offset={-100} className="menu-item bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Certificate</Link>
            </li>

            <li>
              <Link activeClass="active" to="footer" smooth spy offset={-100} className="menu-item bg-gradient-to-bl from-zinc-100 via-zinc-200 to-zinc-300 bg-clip-text text-transparent">Contact Me</Link>
            </li>
          </ul>

          <button className="menu-btn" onClick={toggleMenu}>
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "1.8rem" }}
            >
              {openMenu ? "close" : "menu"}
            </span>
          </button>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
